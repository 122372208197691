.bg {
  /* background-color: rgb(134, 202, 204); */
  background-color: #0CA678;
  padding: 17px;
  border-radius: 10px;
  color: black;
  margin-left: 60px;
  margin-right: 60px;
}

.bg p {
  color: beige;
  font-size: larger;
  font-weight: 550;
}

@media (max-width: 425px) {
  .bg {
    margin-left: 10px;
    margin-right: 10px;
  }

  .bg button {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }
}
