@import url("https://fonts.googleapis.com/css2?family=Belanosima&display=swap");

.title {
  display: flex;
  justify-content: center;
  padding-top: 40px;
}

.title h1 {
  font-size: 30px;
  word-spacing: 4px;
  letter-spacing: 2px;
  background-color: #f0f1f3;
  border: 2px solid #183153;
  border-radius: 20px;
  text-align: center;
  color: #183153;
  padding: 10px 20px 10px 20px;
  font-family: "Belanosima", sans-serif;
  width: 540px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 700;
}

.specialWord {
  color: #0077b5;
  /* font-weight: 700; */
}

.subtitle {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.subtitle h1 {
  color: #183153;
  font-family: "Belanosima", sans-serif;
  font-size: 19px;
}

.specialWord2 {
  font-weight: 550;
  color: #0077b5;
  font-size: 20px;
  text-decoration: underline;
}

.bgApp {
  background-image: url("https://fontawesome.com/images/pro-upgrade-bg.svg");
  background-color: #f0f1f3;
  background-size: auto;
  padding-bottom: 40px;
  min-height: 100vh;
}

@media (max-width: 430px) {
  .title h1 {
    font-size: 28px;
    width: 340px;
  }
}
